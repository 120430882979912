import React, { createContext, useContext, useState } from 'react'
import { Dialog } from '../layout'

const context = createContext({})

export const useCalendar = () => useContext(context)

export const CalendarProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const openCalendar = () => setOpen(true)

  return (
    <context.Provider value={{ openCalendar }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 900, height: 843, background: '#f5f8fa' }}>
          <div style={{ width: 860, height: 803, overflow: 'hidden' }}>
            <iframe src="https://meetings.hubspot.com/dale-anderson" width={860} height={853} style={{ border: 'none' }} />
          </div>
        </div>
      </Dialog>
      {children}
    </context.Provider>
  )
}

import React, { createContext, useContext, useState } from 'react'
import { post } from '../../utilities'
import successImage from '../../assets/contact-success.png'
import './ContactDialog.scss'
import { Dialog } from '../layout'

const context = createContext({})
export const useContactDialog = () => useContext(context)

export const ContactProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const openContactDialog = () => setOpen(true)

  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)

  const close = e => {
    setOpen(false)
    setTimeout(() => {
      setFailure(false)
      setSuccess(false)
    }, 500)
    e && e.preventDefault()
    e && e.stopPropagation()
  }

  const submit = (e) => {
    const formData = new FormData(e.target)
    const formObject = Object.fromEntries(formData.entries())
    post('contact', formObject)
      .then(response => {
        response.status === 200 ? setSuccess(true) : setFailure(true)
      })
      .catch(() => {
        setFailure(true)
      })
    e && e.preventDefault()
    e && e.stopPropagation()
  }

  return (
    <context.Provider value={{ openContactDialog }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div className="contact-dialog">
          {(!success && !failure) && <div className="content">
            <h4>Get in touch</h4>
            <p className="form-section-title">Our friendly team is here to help</p>

            <form onSubmit={submit}>
              <div className="row">
                <input name="name" placeholder="Name (required)" required/>
                <input name="email" placeholder="Email (required)" required type="email"/>
              </div>

              <div className="row">
                <input name="phone" placeholder="Phone" />
                <select name="type" required>
                  <option disabled selected className="select-placeholder" value=''>Enquiry type (required)</option>
                  <option>General enquiry</option>
                  <option>Becoming a facilitator</option>
                  <option>Billing and subscriptions</option>
                  <option>Help and support</option>
                  <option>Running a sim</option>
                  <option>Events and opportunities</option>
                </select>
              </div>

              <div>
                <p className="form-section-title">Products of interest:</p>
                <label>
                  <input name="dragons" type="checkbox"/>
                  The Dragons are Coming
                </label>
                <label>
                  <input name="firstResponder" type="checkbox"/>
                  First Responder
                </label>
                <label>
                  <input name="grimsby" type="checkbox"/>
                  Grimsby's Code
                </label>
                <label>
                  <input name="critters" type="checkbox"/>
                  Woodland Critters
                </label>
              </div>

              <textarea name="message" placeholder="Message"></textarea>

              <div className="row">
                <button className="black" onClick={close} type="button">Cancel</button>
                <button className="aqua" type="submit">Send</button>
              </div>
            </form>
          </div>}

          {success && <div className="result-content content">
            <div className="image-container">
              <img src={successImage} alt="success" />
            </div>
            <h4>We are on our way!</h4>
            <p>Your email has been received and someone from our team will be with you as soon as possible to help!</p>
            <button className="aqua black-border" onClick={close}>Awesome</button>
          </div>}

          {failure && <div className="result-content content">
            An error occurred. Please try again later.
            <button className="aqua black-border" onClick={close}>Close</button>
          </div>}
        </div>
      </Dialog>
      {children}
    </context.Provider>
  )
}
import React, { useRef } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import './MediaSection.scss'

export const MediaSection = ({ video, image, expandIn, expandOut, fullWidth, loop, background, className, children }) => {
  const root = useRef()
  const content = useRef()
  const media = useRef()

  useGSAP(() => {
    const isExpandingOut = () => expandOut && window.innerWidth > 1000

    if(video) {
      ScrollTrigger.create({
        trigger: media.current,
        start: 'top bottom',
        end: () => '+=' + (window.innerHeight * (isExpandingOut() ? 3.5 : 2.5)),
        onToggle: ({ isActive }) => media.current[isActive ? 'play' : 'pause']()
      })
    }

    if(expandIn) {
      const animation = gsap.timeline()
        .from(content.current, {
          width: 0,
          duration: 5,
          ease: 'linear'
        })

      if(expandOut) {
        gsap.matchMedia().add('(min-width: 1001px)', () => {
          animation.to([content.current, media.current], {
            startAt: { width: () => window.innerWidth * 0.6 },
            width: '100vw',
            delay: 4,
            duration: 10,
            ease: 'linear',
            onStart: () => content.current.style.overflow = 'visible',
            onReverseComplete: () => content.current.style.overflow = 'hidden'
          })
        })
      }

      ScrollTrigger.create({
        trigger: root.current,
        scrub: true,
        end: () => isExpandingOut() ? '+=' + (window.innerHeight * 3) : 'center center',
        animation
      })

      ScrollTrigger.create({
        trigger: root.current,
        start: 'center center',
        end: () => '+=' + (window.innerHeight * (isExpandingOut() ? 2.5 : 1)),
        pin: true,
        anticipatePin: 1
      })
    }
  })

  return (
    <section className={`media${fullWidth ? ' full-width' : ''}${expandOut ? ' expand-out' : ''}${className ? ` ${className}` : ''}`} ref={root} style={{ background }}>
      <div>
        <div className="content" ref={content}>
          {image && <img src={image} alt="media" ref={media} />}
          {video && <video src={video} loop={loop || false} playsInline muted ref={media} />}
        </div>
        <div className="children">
          {children}
        </div>
      </div>
    </section>
  )
}
import React from 'react'
import { Navigation, NavigationMenu } from '.'
import { BookDemoButton, Logo } from '../widgets'
import './Header.scss'

export const Header = () => {
  return (
    <header>
      <div style={{ flexBasis: '33%' }}>
        <Logo />
        <NavigationMenu />
      </div>

      <div style={{ flexBasis: '33%', display: 'flex', justifyContent: 'center' }}>
        <Navigation />
      </div>

      <div style={{ flexBasis: '33%', display: 'flex', justifyContent: 'flex-end', gap: 8 }} className="buttons">
        <BookDemoButton />
        <button
          className="login aqua"
          onClick={() => window.location.href = '/portal'}
        >
        </button>
      </div>
    </header>
  )
}
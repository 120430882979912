import React from 'react'
import { BookDemoButton, CircleText, Logo } from '../widgets'
import './Footer.scss'
import { Navigation } from './Navigation'

export const Footer = () => {
  return (
    <footer>
      <div>
        <div className="cta">
          <div>
            <h4>Hit the pause button on boring, it's time for teamwork magic!</h4>
            <div>
              <BookDemoButton content="Let's talk" />
            </div>
          </div>
          <h4>© 2024 All rights reserved</h4>
        </div>
        <div className="navigation">
          <Navigation />
          <nav>
            <h5><a href="/terms">Terms & conditions</a></h5>
            <h5><a href="https://www.instagram.com/podandhive/">Instagram</a></h5>
            <h5><a href="https://www.linkedin.com/company/podandhive/">LinkedIn</a></h5>
          </nav>
          <CircleText text="SERIOUS SOCIAL PLAY" count={2} centerText="Let's play" />
        </div>
      </div>
      <Logo fullWidth />
    </footer>
  )
}
import React, { useState } from 'react'
import { post } from '../../utilities'
import { Dialog } from '../layout'
import successImage from '../../assets/contact-success.png'
import './JoinUsDialog.scss'

export const JoinUsDialog = ({ open, onClose }) => {
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)

  const close = e => {
    onClose()
    setTimeout(() => {
      setFailure(false)
      setSuccess(false)
    }, 500)
    e && e.preventDefault()
    e && e.stopPropagation()
  }

  const submit = (e) => {
    const formData = new FormData(e.target)
    const formObject = Object.fromEntries(formData.entries())
    post('contact', { ...formObject, type: 'Join us' })
      .then(response => {
        response.status === 200 ? setSuccess(true) : setFailure(true)
      })
      .catch(() => {
        setFailure(true)
      })
    e && e.preventDefault()
    e && e.stopPropagation()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="join-us-dialog">
        {(!success && !failure) && <div className="content">
          <h4>Interesting in joining our dream team?</h4>
          <p>Please provide a few details about yourself:</p>

          <form onSubmit={submit}>
            <div>
              <input name="name" placeholder="Name (required)" required />
            </div>
            <div>
              <input name="email" placeholder="Email (required)" required type="email" />
            </div>
            <div>
              <input name="phone" placeholder="Phone" />
            </div>

            <p>Please tell us why you think you would be a great addition to our team:</p>
            <textarea name="message" placeholder="Message"></textarea>

            <div className="row">
              <button className="black" onClick={close} type="button">Cancel</button>
              <button className="aqua" type="submit">Send</button>
            </div>
          </form>
        </div>}

        {success && <div className="result-content content">
        <div className="image-container">
            <img src={successImage} alt="success" />
          </div>
          <h4>Your application has been received!</h4>
          <p>We will be in touch with you as soon as we can!</p>
          <button className="aqua black-border" onClick={close}>Awesome</button>
        </div>}

        {failure && <div className="result-content content">
          An error occurred. Please try again later.
          <button className="aqua black-border" onClick={close}>Close</button>
        </div>}
      </div>
    </Dialog>
  )
}
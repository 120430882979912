import React, { useRef } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import './SubtextSection.scss'

export const SubtextSection = ({ children, aqua, honey, white, black, transparent, uppercase, end = () => '+=' + (window.innerHeight * 1.5), paddingBottom = '100vh', zIndex }) => {
  const root = useRef()
  const h3 = useRef()
  const marker = useRef()
  const container = useRef()

  useGSAP(() => {
    gsap.from(h3.current, {
      scrollTrigger: {
        trigger: container.current,
        start: 'center bottom',
        end: () => '+=' + window.innerHeight,
        scrub: true
      },
      ease: 'power1.out',
      scale: 1.4,
      opacity: 0
    })
    gsap.to(h3.current, {
      scrollTrigger: {
        trigger: container.current,
        start: 'center bottom',
        end: () => '+=' + window.innerHeight,
        scrub: true
      },
      ease: 'power2.out',
      y: () => '-50lvh'
    })
    ScrollTrigger.create({
      trigger: container.current,
      start: 'center bottom',
      end,
      pin: true
    })

    const fadeInElements = h3.current.querySelectorAll('button,.fade')
    if(fadeInElements.length > 0) {
      gsap.from(fadeInElements, {
        scrollTrigger: {
          trigger: marker.current,
          start: 'center center',
          toggleActions: 'play none none reverse'
        },
        opacity: 0,
        duration: 0.6,
        stagger: 0.6
      })
    }

    if(white) {
      ScrollTrigger.create({
        trigger: root.current,
        start: 'top top',
        end: 'bottom top',
        onToggle: ({ isActive }) => {
          document.querySelectorAll('header > nav,.logo,.navigation-menu > button').forEach(element => element.classList[isActive ? 'add' : 'remove']('inverse'))
        }
      })
    }
  })

  return (
    <section
      className={`subtext screen${aqua ? ' aqua' : ''}${honey ? ' honey' : ''}${white ? ' white' : ''}${black ? ' black' : ''}${transparent ? ' transparent' : ''}`}
      style={{ paddingBottom, zIndex }}
      ref={root}
    >
      <div className="container" ref={container}>
        <h3 ref={h3} style={{ textTransform: uppercase ? 'uppercase' : '' }}>
          {children}
        </h3>
      </div>
      <div className="marker" ref={marker}></div>
    </section>
  )
}